import React, {useEffect} from 'react';
import WelcomePage from '../Components/WelcomePage';
const appConfig = require('../data.json');

export default function AdminPage({cms = {}, ...props}) {
  let {entry} = cms;

  return (
    <div>
      <WelcomePage />;
    </div>
  );
}
